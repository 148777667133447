.about_us_container {
  width: 100%;
  margin-bottom: 100px;
}
.about {
  width: 70%;
  margin-bottom: 40px;
}
.about h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
  margin-bottom: 20px;
}
.about h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}
.about_us_container p {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 15px;
}
.text {
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.02);
  width: 48%;
}
.left_photo_right_text,
.right_photo_left_text {
  display: flex;
  gap: 1%;
  margin-bottom: 40px;
}
.shirvanShahPalace2 {
  display: none;
}
.about_us_photo_cont {
  display: flex;
  gap: 1%;
}

.up_down {
  display: flex;
  flex-direction: column;
  row-gap: 5%;
  width: 48%;
}
.up_down_first {
  width: 55%;
}
.half_screen {
  width: 48%;
}
.half_screen img {
  width: 100%;
}
.aboutUsUl{
  padding: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}
@media only screen and (max-width: 767px) {
  .about_us_container {
    margin-bottom: 50px;
  }
  .about {
    width: 100%;
  }
  .about h1 {
    font-family: Space Grotesk;
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
  }
  .about h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .about_us_container p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 20px;
  }
  .text {
    width: 100%;
  }
  .left_photo_right_text,
  .right_photo_left_text {
    display: block;
    margin-bottom: 20px;
  }
  .half_screen {
    width: 100%;
    margin-bottom: 20px;
  }
  .shirvanShahPalace2 {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  .about_us_photo_cont {
    display: flex;
  }
}
