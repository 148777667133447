.wrapper {
  width: 100%;
  padding: 0 6.6%;
}
.contact_us_container {
  margin-top: 120px;
  margin-bottom: 100px;
  display: flex;
  gap: 20px;
}
.left {
  width: 48%;
  border-radius: 18px;
  background: #f4da4e;
  padding: 30px 200px 0 30px;
}
.right {
  width: 48%;
  border-radius: 18px;
  background: rgba(0, 0, 0, 0.02);
  padding: 30px 0 0 30px;
  position: relative;
}
.contact_info {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 14px;
}
.contact_info p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.contact_info:last-child {
  margin-bottom: 53px;
}
.left h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
  margin-bottom: 35px;
}
.left h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 33px;
  padding-right: 3.7%;
}
.right h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  margin-bottom: 40px;
}
.contact_us_form input {
  border: none;
  border-bottom: 1px solid #000000;
  background: none;
  width: 100%;
  padding: 11px 2px;
  margin-bottom: 40px;
}
input:focus {
  outline: none;
}
.inputs {
  display: flex;
  gap: 20px;
  padding: 0 23px 0 13px;
}
.submit {
  position: absolute;
  right: 26px;
  border: none;
  background: transparent;
}
@media only screen and (max-width: 767px) {
  /*Mobile*/
  .contact_us_container {
    display: block;
    margin-top: 80px;
    margin-bottom: 60px;
  }
  .left,
  .right {
    width: 100%;
    padding: 20px;
    margin-bottom: 30px;
  }

  .left h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
  }
  .contact_info {
    gap: 6px;
  }
  .contact_info:last-child {
    margin-bottom: 25px;
  }
  .left h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 20px;
    padding-right: 3.7%;
  }
  .right h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .contact_info p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  .inputs {
    display: block;
    padding: 0 10px 0 0;
  }
}
