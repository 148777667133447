/* .container {
  width: 100%;
}
.mainImage {
  width: 100%;
}
.mainImage img {
  width: 100%;
}
.tours_selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.12% 2.5%;
  width: 100%;
  margin: 0 auto;
}
.li {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 320px;
}
.li:hover::before {
  background-color: #ffe141;
}
.li span {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}
.selection_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.selected {
  background: var(--Black-7, rgba(0, 0, 0, 0.1));
}
.selected svg {
  fill: #ffe141;
}
.tourName {
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
  margin-bottom: 40px;
}
.header_with_plus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.header_with_plus svg {
  fill: black;
}
hr {
  border-color: rgba(0, 0, 0, 1);
  margin-bottom: 25px;
}
.day_text {
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding: 12px;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 18px;
  background: rgba(0, 0, 0, 0.02);
}
@media only screen and (max-width: 767px) {
  .selection_container {
    width: 100%;
  }
  .tours_selection {
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
  }
  .li span {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
  .li {
    background: var(--Black-7, rgba(0, 0, 0, 0.1));
  }
}
 */
.container {
  width: 100%;
}
.mainImage {
  width: 100%;
}
.mainImage img {
  width: 100%;
}
.tours_selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.07rem 0.15625rem; /* 1.12% 2.5% */
  width: 100%;
  margin: 0 auto;
}
.li {
  display: flex;
  align-items: center;
  gap: 0.625rem; /* 10px */
  cursor: pointer;
  padding: 0.625rem 1.25rem; /* 10px 20px */
  border-radius: 20rem; /* 320px */
}
.li:hover::before {
  background-color: #ffe141;
}
.li span {
  font-size: 1.25rem; /* 20px */
  font-weight: 500;
  line-height: 1.875rem; /* 30px */
}
.selection_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem; /* 24px */
}
.selected {
  background: var(--Black-7, rgba(0, 0, 0, 0.1));
}
.selected svg {
  fill: #ffe141;
}
.programTitles {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 500;
  margin-bottom: 1.5625rem;
}

.tourName {
  font-size: 2.5rem; /* 40px */
  font-weight: 700;
  line-height: 3.4375rem; /* 55px */
  margin-bottom: 2.5rem; /* 40px */
}
.header_with_plus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.header_with_plus svg {
  fill: black;
}
hr {
  border-color: rgba(0, 0, 0, 1);
  margin-bottom: 1.5625rem;
  /* 25px */
}
.day_text {
  margin-bottom: 1.5625rem; /* 25px */
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem; /* 12px */
  color: rgba(0, 0, 0, 0.6);
  border-radius: 1.125rem; /* 18px */
  background: rgba(0, 0, 0, 0.02);
}
@media only screen and (max-width: 767px) {
  .selection_container {
    width: 100%;
  }
  .tours_selection {
    width: 100%;
    flex-wrap: wrap;
    gap: 0.625rem; /* 10px */
    justify-content: flex-start;
  }
  .li span {
    font-size: 0.875rem; /* 14px */
    font-weight: 500;
    line-height: 1.3125rem; /* 21px */
  }
  .li {
    background: var(--Black-7, rgba(0, 0, 0, 0.1));
  }
}
